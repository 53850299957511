import React, {useEffect, useRef} from "react";
import VirtGamepad from "./gamepad";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../../redux/AlertSlice";
import {Avatar, Badge, ListItemAvatar, ListItemText, Menu, MenuItem, SpeedDialAction} from "@mui/material";
import {SportsEsportsTwoTone, VideogameAssetTwoTone} from "@mui/icons-material";
import {lightBlue, red} from "@mui/material/colors";

const GamepadInput = ({open, onClose, anchorEl, cli, inputReady}) => {
  const dispatch = useDispatch();
  const [available, setAvailable] = React.useState({});
  const [connected, setConnected] = React.useState({});
  const connectAutomatically = useSelector(state => state.client_settings.gamepad_autoconnect)


  const connectGamepad = (gamepad) => {
    if (gamepad.buttons.length >= 17) {
      setConnected((prevState) => {
        return Object.assign({}, prevState, {[gamepad.index]: new VirtGamepad(cli, gamepad)})
      });
    } else {
      dispatch(setAlert({msg: "Cannot connect gamepad with less than 16 buttons", severity: "warning"}))
    }
  }

  const disconnectGamepad = (gamepadIdx) => {
    if (connected[gamepadIdx]) {
      let gp = connected[gamepadIdx];
      gp.disconnect();
      setConnected((prevState) => {
        delete prevState[gamepadIdx];
        return Object.assign({}, prevState);
      })
    }
  }

  const onGamepadConnected = (ev) => {
    newGamepadAvailable(ev.gamepad);
    dispatch(setAlert({msg: "New Gamepad connected", severity: "info"}));
  }
  const newGamepadAvailable = (gamepad) => {
    setAvailable((prevState) => {
      return Object.assign({}, prevState, {[gamepad.index]: gamepad})
    });
    if (connectAutomatically) {
      connectGamepad(gamepad);
    }
  }
  const gamepadDisconnected = (gamepad) => {
    setAvailable((prevState) => {
      delete prevState[gamepad.index];
      return Object.assign({}, prevState);
    });
    disconnectGamepad(gamepad.index);
  }
  const onGamepadDisconnected = (ev) => {
    gamepadDisconnected(ev.gamepad);
    dispatch(setAlert({msg: "Gamepad disconnected", severity: "error"}));
  }
  const loadGamepads = () => {
    let current_gamepads = navigator.getGamepads();
    for (const [idx, gp] of Object.entries(current_gamepads)) {
      if (gp) {
        newGamepadAvailable(gp)
      }
    }
  }
  const gameloop = () => {
    let current_gamepads = navigator.getGamepads();
    for (const [idx, gp] of Object.entries(connected)) {
      if (current_gamepads[idx]) {
        gp.sendInput(current_gamepads[idx])
      }
    }
    requestAnimationFrame(gameloop)
  }
  useEffect(() => {
    if (cli && inputReady) {
      window.addEventListener("gamepadconnected", onGamepadConnected)
      window.addEventListener("gamepaddisconnected", onGamepadDisconnected)
      // register existing gamepads()
      loadGamepads();
      return () => {
        for (const [_, gp] of Object.entries(connected)) {
          gp.disconnect()
        }
        window.removeEventListener("gamepadconnected", onGamepadConnected)
        window.removeEventListener("gamepaddisconnected", onGamepadDisconnected)
      }
    }

  }, [cli, inputReady]);

  useEffect(() => {
    let start = requestAnimationFrame(gameloop);
    return () => {
      cancelAnimationFrame(start)
    }
  }, [connected])

  return <>
    {/*<IconButton ref={gpButton} onClick={toggleOpen} sx={{m: 2}}>*/}
    {/*  <Badge color={"secondary"}*/}
    {/*         badgeContent={Object.keys(connected).length}><SportsEsportsTwoTone/></Badge>*/}
    {/*</IconButton>*/}
    {/*<Badge color={"info"}*/}
    {/*       badgeContent={Object.keys(connected).length}>*/}
    {/*  <SpeedDialAction ref={gpButton} onClick={toggleOpen} icon={*/}
    {/*    <SportsEsportsTwoTone/>*/}
    {/*  } tooltipTitle={"Gamepads"}*/}
    {/*                   tooltipOpen={true}*/}
    {/*  >*/}
    {/*  </SpeedDialAction> </Badge>*/}


    <Menu sx={{minWidth: "100px"}} anchorEl={anchorEl} open={open} onClose={onClose}>
      {Object.keys(available).map((idx) => {
        return {idx, gamepad: available[idx], active: !!connected[idx]}
      }).map(({idx, gamepad, active}) => {
        return <MenuItem key={idx} onClick={() => active ? disconnectGamepad(idx) : connectGamepad(gamepad)}>
          <ListItemAvatar>
            <Avatar sx={{bgcolor: active ? lightBlue[200] : red[200]}}>
              <VideogameAssetTwoTone/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={gamepad.id} secondary={active ? "Connected" : "Not Connected"}/>
        </MenuItem>
      })}
      {Object.keys(available).length === 0 &&
        <MenuItem disabled>
          Press any button on gamepad to enable
        </MenuItem>
      }

    </Menu>
  </>
}
export default GamepadInput;