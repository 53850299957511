import apiLinks from "./apiLinks";

const forgot_password = async (email) => {
  const resp = await fetch(apiLinks.forgot_password, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify({email})
  })
  if (200 <= resp.status && resp.status < 300) {
    return {success: true}
  } else {
    const data = await resp.json();
    return {success: false, data}
  }
}

export default forgot_password;