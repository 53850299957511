const UNKNOWN = "unknown";
const MOVE = "move";
const SCROLL = "scroll";

class TouchGestures {
  touchMode = UNKNOWN;
  kbdInp;
  existingTouches = {}
  scrollMultiplier = 1;
  moveMultiplier = 1;
  elem;

  threshold = 2;
  refs = [];

  constructor(elem, kbdInp, scrollMultiplier, moveMultiplier) {
    this.elem = elem;
    this.kbdInp = kbdInp;
    this.scrollMultiplier = scrollMultiplier * 2;
    this.moveMultiplier = moveMultiplier * 2;
    this.refs = [this.onTouchMove.bind(this), this.onTouchStart.bind(this), this.onTouchEnd.bind(this)]

    elem.addEventListener("touchmove", this.refs[0], {passive: false});
    elem.addEventListener("touchstart", this.refs[1], {passive: false});
    elem.addEventListener("touchend", this.refs[2], {passive: false});
    elem.addEventListener("touchcancel", this.refs[2], {passive: false});
  }

  destroy() {
    this.elem.removeEventListener("touchmove", this.refs[0]);
    this.elem.removeEventListener("touchstart", this.refs[1]);
    this.elem.removeEventListener("touchend", this.refs[2]);
    this.elem.removeEventListener("touchcancel", this.refs[2]);
  }

  onTouchStart = (e) => {
    e.preventDefault()
    Object.assign(this.existingTouches, e.touches);
  }

  onTouchMove = (e) => {
    e.preventDefault()
    if (this.touchMode === UNKNOWN && (Math.abs(e.touches[0]?.pageX - this.existingTouches[0].pageX) > this.threshold)) {
      if (e.touches.length === 1) {
        this.touchMode = MOVE
      } else if (e.touches.length === 2) {
        this.touchMode = SCROLL;
      }
      this.existingTouches[0] = e.touches[0];
    }
    if (this.touchMode === MOVE) {
      let dx = e.touches[0].pageX - this.existingTouches[0].pageX;
      let dy = e.touches[0].pageY - this.existingTouches[0].pageY;
      this.kbdInp.sendMouseMove({x: dx * this.moveMultiplier, y: dy * this.moveMultiplier})
      this.existingTouches[0] = e.touches[0];
    }
    if (this.touchMode === SCROLL) {
      let dx = e.touches[0].pageX - this.existingTouches[0].pageX;
      let dy = e.touches[0].pageY - this.existingTouches[0].pageY;
      this.kbdInp.sendMouseScroll({x: dx * this.scrollMultiplier * -1, y: dy * this.scrollMultiplier})
      this.existingTouches[0] = e.touches[0];
      this.existingTouches[1] = e.touches[1];
    }
  }

  onTouchEnd = (e) => {
    e.preventDefault();
    if (this.touchMode === UNKNOWN && e.touches.length === 0) {
      if (Object.keys(this.existingTouches).length === 1) {
        e.preventDefault()
        this.kbdInp.sendMouseDown(0)
        this.kbdInp.sendMouseUp(0)
      } else if (Object.keys(this.existingTouches).length === 2) {
        e.preventDefault()
        this.kbdInp.sendMouseDown(2)
        this.kbdInp.sendMouseUp(2)
      }
    }
    if (e.touches.length === 0) {
      this.touchMode = UNKNOWN
      this.existingTouches = {}
    }
  }
}

export default TouchGestures;