import {createSlice} from "@reduxjs/toolkit";


const AlertSlice = createSlice({
  name: "canvas",
  initialState: {
    open: false,
  },
  reducers: {
    setAlert: (state, action) => {
      return {open: true, ...action.payload}
    },
    clearAlert: (state, action) => {
      state.open = false;
    }
  },
})


export const {setAlert, clearAlert} = AlertSlice.actions;
export default AlertSlice.reducer