import {configureStore} from '@reduxjs/toolkit'
import AuthReducer from "./AuthSlice";
import LoginReducer from "./LoginSlice";
import SignupReducer from "./SignupSlice";
import DevicesReducer from "./DevicesSlice";
import SettingsReducer from "./SettingsSlice";
import ClientSettingsReducer from "./ClientSettingsSlice";
import AudioReducer from "./AudioSlice";
import CanvasReducer from "./CanvasSlice";
import DefaultSettingsReducer from "./DefaultSettingsSlice";
import AlertReducer from "./AlertSlice";

export default configureStore({
  reducer: {
    auth: AuthReducer,
    login: LoginReducer,
    signup: SignupReducer,
    devices: DevicesReducer,
    settings: SettingsReducer,
    client_settings: ClientSettingsReducer,
    defaultSettings : DefaultSettingsReducer,
    audio: AudioReducer,
    canvas:CanvasReducer,
    alert:AlertReducer,
  }
})