import {createSlice} from "@reduxjs/toolkit";


const CanvasSlice = createSlice({
  name: "canvas",
  initialState: {
  },
  reducers: {
    setBoundingRect: (state, action) => {
      return action.payload
    }
  },
})


export const {setBoundingRect} = CanvasSlice.actions;
export default CanvasSlice.reducer