import React, {useMemo, useEffect, useRef, useState} from "react";
import KbdMouse from "./kbd_mouse";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import touchGestures from "./touchGestures";

const AbsoluteKeyboardInput = ({cli, inControl, onControlChange, move_multiplier, scroll_multiplier}) => {

  const element = useRef();
  const [kbdInp, setKbdInp] = useState(null);
  const boundingRect = useSelector(state => state.canvas);
  const releaseInput = () => {
    onControlChange(false);
  }
  useEffect(() => {
    if (element.current && inControl) {
      element.current.focus();
      navigator.keyboard?.lock()
      let keyboardInput = new KbdMouse(cli)
      setKbdInp(keyboardInput);
      let touch = new touchGestures(element.current, keyboardInput, scroll_multiplier, move_multiplier );
      return () => {
        releaseInput()
        touch.destroy();
        navigator.keyboard?.unlock();
      }
    }
  }, [inControl, element]);

  useEffect(() => {
    if (element.current) {
      const ele = element.current;
      ele.addEventListener("wheel", mouseScroll)
      return () => ele.removeEventListener("wheel", mouseScroll)
    }
  }, [element, kbdInp]);
  const mouseMove = (e) => {
    const x = e.pageX - boundingRect.x;
    const y = e.pageY - boundingRect.y;
    const normX = Math.min(Math.max(0, Math.round((x / boundingRect.width) * 65535)), 65535);
    const normY = Math.min(Math.max(0, Math.round((y / boundingRect.height) * 65535)), 65535);

    kbdInp?.sendMouseMoveAbs({x: normX, y: normY})
  }
  const mouseDown = (e) => {
    kbdInp?.sendMouseDown(e.button)
    e.preventDefault()
  }
  const mouseUp = (e) => {
    kbdInp?.sendMouseUp(e.button)
    e.preventDefault()
  }

  const keyDown = (e) => {
    if ((e.key === "m" || e.key==="M") && e.ctrlKey) {
      releaseInput()
      kbdInp?.sendKeyUp(17)
      kbdInp?.sendKeyUp(e.keyCode)
      return
    }
    e.preventDefault()
    kbdInp?.sendKeyDown(e.keyCode)
  }
  const keyUp = (e) => {
    e.preventDefault()
    kbdInp?.sendKeyUp(e.keyCode)
  }

  const mouseScroll = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const dx = e.deltaX * (e.deltaMode === WheelEvent.DOM_DELTA_LINE ? 120 : 1);
    const dy = e.deltaY * (e.deltaMode === WheelEvent.DOM_DELTA_LINE ? 120 : 1);
    kbdInp?.sendMouseScroll({x: dx * scroll_multiplier, y: -1 * dy * scroll_multiplier})
  }
  return <Box sx={{
    height: "100vh", width: "100vw", position: "absolute", '&:focus': {
      outline: "none",
    }
  }} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp}
              onKeyDown={keyDown} onKeyUp={keyUp} onContextMenu={e => e.preventDefault()}
              ref={element} tabIndex={0}/>
}
export default AbsoluteKeyboardInput;