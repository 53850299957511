import {createSlice} from "@reduxjs/toolkit";


const defaultSettingConfig = {
    resolution : "FullHD",
    codec : "H265",
    color_format : "RGB",
    framerate : 60,
    hardware_acceleration: 'prefer-hardware',
    client_Keyboard_type: 'WINDOWS',
    mouse_optimized : 'GAMING'
}


const LOCALSTORE_KEY = "default_settings"

let defaultSettings;

let storedSettings = window.localStorage.getItem(LOCALSTORE_KEY);
if (storedSettings) {
    defaultSettings = Object.assign({}, defaultSettingConfig, JSON.parse(storedSettings));
} else {
    defaultSettings = defaultSettingConfig;
}

const DefaultSettingsSlice = createSlice({
    name: "defaultSetting",
    initialState: defaultSettings,
    reducers: {
        setDefaultSetting: (state, action) => {
            state = action.payload
            window.localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(state))
            console.log("saving default setting to persistent store", JSON.stringify(state))
            return state
        }
    },
})

export const {setDefaultSetting} = DefaultSettingsSlice.actions;
export default DefaultSettingsSlice.reducer