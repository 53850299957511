import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import GAWSClient from "./GAWSClient";
import {Box, Button, Container, Typography} from "@mui/material";
import {RestartAlt} from "@mui/icons-material";
import WebrtcClient from "./WebrtcClient";
import WebCodecPlayer from "./players/WebCodecPlayer/WebCodecPlayer";
import Overlay from "./Overlay";
import {useSelector} from "react-redux";

const StreamClient = () => {
  const {deviceId} = useParams();
  const [wsStatus, setWsStatus] = useState("Not initialized")
  const [webRTCStatus, setWebRTCStatus] = useState("Not initialized")
  const [wsConn, setWsConn] = useState(null);
  const [webrtcConn, setWebrtcConn] = useState(null);
  const disconnected = Boolean(wsStatus === "closed" || webRTCStatus === "disconnected")
  const wsReady = Boolean(wsStatus === "accepted")
  const cliReady = Boolean(webRTCStatus === "connected")
  const [inputReady, setInputReady] = useState(false);
  const streamConfig = useSelector(state => state.settings);

  const connect = () => {
    let cli = new GAWSClient()
    setWsConn(cli);
    cli.onStatusChanged = (status) => setWsStatus(status)
    cli.onDisconnect = () => {
      // do something else
    }
    return () => {
      cli.destroy()
    }
  }
  const getStatusText = () => {
    return wsReady ? webRTCStatus : wsStatus
  }

  useEffect(connect, []);

  useEffect(() => {
    if (wsReady) {
      const webrtc = new WebrtcClient(deviceId, wsConn, (status) => setWebRTCStatus(status), () => setInputReady(true), streamConfig)
      setWebrtcConn(webrtc)
      return () => {
        webrtc.destroy()
        setWebrtcConn(null);
      }
    }
  }, [wsReady]);

  useEffect(() => {
    if (webrtcConn) {
      webrtcConn.setConfig(streamConfig)
    }
  }, [streamConfig]);

  return <>
    <Container sx={{
      display: 'flex',
      height: "100vh",
      width: "100vw",
      flexDirection: 'start',
      textAlign: 'center',
      justifyContent: 'center',
      overflow: "hidden"
    }}>
      {cliReady ? <Overlay cli={webrtcConn} inputReady={inputReady}><WebCodecPlayer cli={webrtcConn}/></Overlay> :
        <Box sx={{alignSelf: 'center', justifySelf: 'center'}}>
          <Typography variant="subtitle" color={"textPrimary"}>Connecting ...</Typography>
          <Typography component={"div"} variant={"caption"} color={"textSecondary"}>{getStatusText()}</Typography>
          {disconnected && <Button onClick={connect} startIcon={<RestartAlt/>}>Reconnect</Button>}
        </Box>}
    </Container>
  </>


}

export default StreamClient