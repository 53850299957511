import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  styled
} from "@mui/material";
import {useEffect, useState} from "react";
import {Update} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {clearAlert} from "../../redux/AlertSlice";


const AppContainer = styled('div', {
  name: 'GAWebApp', slot: 'root-container',
})(({theme}) => ({
  minHeight: "100%", width: "100%", display: 'flex', position: 'absolute', overflow: "hidden",
}))


const FullBackground = styled('div', {
  name: 'GAWebApp', slot: 'background'
})(({theme}) => ({
  // backgroundImage: `url('${backgroundImage}')`,
  backgroundSize: 'cover', // background: rgb(44,104,162);
  background: 'radial-gradient( circle at 70vw 30vh, rgba(19,47,74,1) 0%, rgba(0,0,0,1) 60%)',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  display: 'block',
  height: "100vh",
  width: "100vw",
  zIndex: -1,
  overflow: 'hidden',
}))

const BaseLayout = (props) => {
  const [update, setUpdate] = useState();
  const alert = useSelector(state => state.alert);

  const dispatch = useDispatch();
  const closeAlert = () => {
    dispatch(clearAlert())
  }

  useEffect(() => {
    window.addEventListener("update_available", handleUpdate)
    return () => {
      window.removeEventListener("update_available", handleUpdate)
    }
  }, []);

  const handleUpdate = (e) => {
    console.log("updateEvent")
    setUpdate((prevState) => {
      return e.detail
    })
  }
  return (<AppContainer>
    {/*<BlurFilter/>*/}
    <FullBackground/>
    {props.children}
    <Dialog open={!!update} onClose={() => setUpdate(undefined)}>
      <DialogTitle>New update available</DialogTitle>
      <DialogContent>

        <DialogContentText>
          Please update the page to continue
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          update(true)
          setUpdate(undefined)
        }} startIcon={<Update/>}>Update</Button>
      </DialogActions>
    </Dialog>
    <Snackbar open={alert.open} onClose={closeAlert} autoHideDuration={4000}
              anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Alert
        onClose={closeAlert}
        severity={alert.severity}
        variant="filled"
      >
        {alert.msg}
      </Alert>
    </Snackbar>
  </AppContainer>)
}

export default BaseLayout