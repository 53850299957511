const base_api = process.env.NODE_ENV === "development" ? '//' + window.location.hostname + ":3000/api" : '/api'
const base_ws = process.env.NODE_ENV === "development" ? getwsProtocol() + window.location.hostname + ':3000/api/client/ws' : getwsProtocol() + window.location.host + '/api/client/ws'
// const base_api = '//' + window.location.hostname + ":3000" + '/api'
// const base_ws = getwsProtocol() + window.location.hostname + ":3000" + '/api/client/ws'

const oauth_base = base_api + '/oauth'
const auth_base = base_api + '/auth'

const apiLinks = {
  auth: auth_base,
  signup: auth_base + '/signup',
  login: auth_base + '/login',
  logout: auth_base + '/logout',
  forgot_password: auth_base + '/forgot_password',
  reset_password: auth_base + '/reset_password',
  oauth_google: oauth_base + '/google',

  add_device: base_api+"/client/add_device_otp",

  device: {
    base: base_api + "/devices",
    list: "",
    update: "/:device_id",
    delete: "/:device_id"
  },

  ws: base_ws
}

export default apiLinks;

function getwsProtocol() {
  if (window.location.protocol === "https:") {
    return "wss://"
  }
  return "ws://"
}
