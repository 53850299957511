import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Container,
  Divider,
  InputAdornment,
  Link,
  styled,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import {
  AccountCircleOutlined, KeyOutlined, Password, SendOutlined,
} from "@mui/icons-material";

import AppLogo from '../../images/icon.svg'
import {useNavigate, Link as Rlink, useSearchParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import forgot_password from "../../api/forgot_password";
import {useDispatch} from "react-redux";
import {setAlert} from "../../redux/AlertSlice";
import reset_password from "../../api/reset_password";

const StyledContainer = styled(Container)(({theme}) => ({
  alignSelf: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`
}))

const FormCard = (props) => {
  return <Card {...props} component={'form'}/>
}
const ResetPassword = (props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [errors_raw, setErrorsRaw] = useState({});
  const [searchParams] = useSearchParams();
  const token = useMemo(() => {
    return searchParams.get("k") || undefined;
  }, [searchParams]);

  const dispatch = useDispatch();
  const errors = useMemo(() => {
    let errMap = {}
    for (const k in errors_raw) {
      errMap[k] = errors_raw[k].join(". ")
    }
    return errMap
  }, [errors_raw]);
  const navigate = useNavigate()

  useEffect(() => {
    if (errors[""]) {
      dispatch(setAlert({msg: errors[""], severity: "error"}))
    }
  }, [errors]);
  const resetPassword = (e) => {
    const formData = new FormData(e.target);
    let payload = Object.fromEntries(formData.entries())
    e.preventDefault();
    setIsLoading(true);
    reset_password(token, payload).then(r => {
      if (r.success) {
        dispatch(setAlert({msg: "Password reset successful", severity: "success"}))
        navigate("/login")
      } else {
        setErrorsRaw(r.data.errors);
      }
    }).catch(e => {
      dispatch(setAlert({msg: "Failed to connect to server", severity: "error"}))
    }).finally(() => {
      setIsLoading(false)
    })
  }
  return (<>
    <StyledContainer maxWidth={"xs"}
                     component={FormCard}
                     elevation={10}
                     variant={'elevation'} type={'glass'}
                     onSubmit={resetPassword}
    >

      <img style={{height: theme.spacing(4), width: 'auto'}} src={AppLogo} alt={"logo"}/>
      <Box sx={{my: 2}}>
        <Typography sx={{my: 2}} variant={"h4"} color={'primary'}>Password reset</Typography>
        <Typography variant={"h6"} color={'textSecondary'}>
          Enter new password for <Typography variant={"h6"} component={'span'}
                                             color={'textPrimary'}>
          Gamearena
        </Typography>
        </Typography>
      </Box>

      <TextField
        disabled={isLoading}
        autoFocus
        sx={{my: 2}}
        name={'password'}
        InputProps={{
          startAdornment: (<InputAdornment position="start">
            <Password color={'primary'}/>
          </InputAdornment>)
        }}
        label={"Password"}
        type={"password"}
        error={errors?.email}
        helperText={errors?.email}
      /><TextField
      disabled={isLoading}
      autoFocus
      sx={{my: 2}}
      name={'password_confirmation'}
      InputProps={{
        startAdornment: (<InputAdornment position="start">
          <Password color={'primary'}/>
        </InputAdornment>)
      }}
      label={"Re-enter Password"}
      type={"password"}
      error={errors?.password_confirmation}
      helperText={errors?.password_confirmation}
    />
      <Divider sx={{mt: 2}}/>
      <CardActions sx={{my: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Box sx={{position: 'relative'}}>
          <Button
            disabled={isLoading}
            type={'submit'} endIcon={<SendOutlined/>} variant={"outlined"}>
            Reset Password
          </Button>
          {isLoading && (<CircularProgress size={24} sx={{
            position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px',
          }}/>)}
        </Box>
      </CardActions>
    </StyledContainer>
  </>)
}

export default ResetPassword