import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  Slider,
  SpeedDialAction,
  styled,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import {
  Cancel,
  CodeTwoTone,
  Fullscreen,
  FullscreenExit,
  ImageAspectRatioTwoTone,
  LinkOffOutlined,
  Mouse,
  NetworkCheck,
  RefreshTwoTone,
  Save,
  Settings,
  SpeedTwoTone,
  SportsEsports,
  VolumeDown,
  VolumeOff,
  VolumeUp
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStreamConfig} from "../../redux/SettingsSlice";
import {setVolume} from "../../redux/AudioSlice";
import KeyboardInput from "./inputs/KeyboardInput";
import GamepadInput from "./inputs/GamepadInput";
import AbsoluteKeyboardInput from "./inputs/AbsoluteKeyboardInput";
import ControlFab from "./ControlFab";
import WakeLock from "./util/WakeLock";
import {setClientConfig} from "../../redux/ClientSettingsSlice";

const SettingsDialog = styled(Dialog)((theme) => ({}))

const VolumeControl = () => {
  const [open, setOpen] = useState(false);
  const volume = useSelector(state => state.audio.volume);
  const dispatch = useDispatch();
  const anchorEl = useRef();
  return <>
    <IconButton ref={anchorEl} onClick={() => setOpen(true)}
                sx={{my: 2, mr: 12}}>
      {volume === 0 ? <VolumeOff/> : <VolumeUp/>}
    </IconButton>
    <Popover slotProps={{
      paper: {

        sx: {
          px: 2, display: 'flex',
          flexDirection: "column",
          alignItems: 'center',
          justifyContent: 'center'
        },
      }
    }} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={open} onClose={() => setOpen(false)}
             anchorEl={anchorEl.current}>
      <VolumeUp sx={{my: 2}}/>
      <Slider sx={{height: "150px"}} orientation={"vertical"} min={0} max={100} step={10}
              onChange={(e, v) => dispatch(setVolume(v))}
              value={volume}/>
      <VolumeDown sx={{my: 2}}/>
    </Popover>
  </>
}
const Overlay = ({children, cli, inputReady}) => {
  const [open, setOpen] = useState(false);
  const [fabOpen, setFabOpen] = useState(false);
  const [inControl, setInControl] = useState(false);
  const [menuOpen, setMenuOpen] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const streamSettings = useSelector(state => state.settings);
  const [settingsState, setSettingState] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const moveMult = useSelector(state => state.client_settings.movement_multiplier);
  const scrollMult = useSelector(state => state.client_settings.scroll_multiplier);
  const cursorCap = useSelector(state => state.client_settings.cursor_capture);
  const clientSettings = useSelector(state => state.client_settings);
  const gamepadButton = useRef(null);
  const navigate = useNavigate();
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen)
  }


  const handleFullscreenchange = () => {
    setFullscreen(!!document.fullscreenElement)
  }
  useEffect(() => {
    if (fullscreen && !document.fullscreenElement) {
      document.querySelector('body').requestFullscreen();
    } else if ((!fullscreen) && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [fullscreen]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenchange)
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenchange)
    }
  }, [])


  useEffect(() => {
    setSettingState(streamSettings);
  }, [streamSettings]);
  const toggleOpen = () => setOpen(!open);
  const openMenu = (menuId) => {
    return (e) => {
      setAnchorEl(e.currentTarget);
      setMenuOpen(menuId)
    };
  }
  const setValue = (name, value) => {
    setSettingState({...settingsState, [name]: value})
    closeMenu()
  }

  const toggleCursorCapture = () => {
    dispatch(setClientConfig({...clientSettings, cursor_capture: !cursorCap}))
  }
  const closeMenu = () => {
    setMenuOpen("");
  }
  return <>
    {children}
    {cursorCap ?
      <KeyboardInput cli={cli} inControl={inControl} onControlChange={setInControl} move_multiplier={moveMult}
                     scroll_multiplier={scrollMult}/> : <AbsoluteKeyboardInput
        cli={cli} inControl={inControl} onControlChange={setInControl} move_multiplier={moveMult}
        scroll_multiplier={scrollMult}
      />}
      <WakeLock/>
      <GamepadInput open={menuOpen === "gamepad_menu"} anchorEl={anchorEl} cli={cli}
                    inputReady={inputReady} onClose={closeMenu}/>
      <Box sx={{bgcolor: "rgba(0,0,0,0.2)", display: inControl ? "none" : "flex"}} position={"absolute"} top={0}
           left={0}
           height={"100%"}
           width={"100%"}
           flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant={"h3"} color={"textSecondary"}><Typography component={'span'} variant="h3"
                                                                       color={"textPrimary"}>Double
          click</Typography> to
          take control</Typography>

        <Typography variant={"h3"} color={"textSecondary"}><Typography component={'span'} variant="h3"
                                                                       color={"textPrimary"}>Ctrl +
          M</Typography> to release control</Typography>
      </Box>
      <Box sx={{display: inControl ? "none" : "flex"}} flexDirection={"column"} position={"absolute"} height={"100%"}
           width={"100%"} justifyContent={"space-between"} onDoubleClick={(e) => {
        console.log(e)
        if (!open) {
          setInControl(true)
        }
      }}>
        <Box color={"#fff"} display={"flex"} justifyContent={"flex-end"} flexDirection={"row"}>
          <VolumeControl/>
        </Box>

      </Box>

      <ControlFab ariaLabel={"Overlay Control"} onOpen={() => {
        setFabOpen(true)
        setInControl(false)
      }} onClose={() => setFabOpen(false)}
                  open={fabOpen}
                  icon={<SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="112.86" height="79.599" viewBox="0 0 667.811 471">
                      <path className="cls-1"
                            d="M1165.11,752.307s1.35-12.665-23.28-19.812c-27.6-8.01-145.161-15.268-222.489-13.836C856.82,719.816,764.2,725.624,733,741c-21.2,10.447,3.7,46.975,80,59,0,0-28.851.69-67-7-129.031-26.009-228.857-65.587-108.837-105.944,163.144-54.858,487.887-40.09,577.647-2.376C1319.06,728.483,1165.11,752.307,1165.11,752.307ZM1234,819s-62.38,73.267-73,147.921c-11.8,82.949,36.41,114.229,36.41,114.229-8.69,2.22-119.41,28.99-119.41,28.99s-31.79-153.711-70-147.445c-43.875,7.2-47,158.395-47,158.395l-90,.91s-22.435-156.283-63.943-159.453C754.022,958.5,747.533,1108.46,749,1106.48l-120.295-26.46S666.974,1032.36,658,972.2c-11.55-77.415-60-147.921-60-147.921C979.724,933.6,1234,819,1234,819Z"
                            transform="translate(-583.219 -651)"/>
                    </svg>
                  </SvgIcon>}
                  FabProps={{color: "primary", size: "small"}}
      renderChildren = {(tooltipPlacement)=>{
        return [<SpeedDialAction
            key={"fullscreen"}
            icon={fullscreen ? <FullscreenExit/> :
              <Fullscreen/>}
            onClick={toggleFullscreen}
            tooltipTitle={fullscreen ? "Windowed" : "Fullscreen"}
            tooltipOpen={true}
            tooltipPlacement={tooltipPlacement}
            color={"primary"}
          />,
          <SpeedDialAction
            key={"cursor"}
            onClick={() => toggleCursorCapture()}
            icon={<Mouse/>}
            tooltipTitle={cursorCap ? "Gaming" : "Desktop"}
            tooltipOpen={true}
            tooltipPlacement={tooltipPlacement}
            color={"primary"}
          />,
          <SpeedDialAction
            key={"gamepad"}
            onClick={openMenu("gamepad_menu")}
            ref={gamepadButton}
            icon={<SportsEsports/>}
            tooltipTitle={"Gamepads"}
            tooltipOpen={true}
            tooltipPlacement={tooltipPlacement}
            color={"primary"}
          />,
          <SpeedDialAction
            key={"disconnect"}
            onClick={() => navigate("/")}
            icon={<LinkOffOutlined/>}
            tooltipTitle={"Disconnect"}
            tooltipOpen={true}
            tooltipPlacement={tooltipPlacement}
            color={"primary"}
          />,
          <SpeedDialAction
            key={"settings"}
            onClick={toggleOpen}
            icon={<Settings/>}
            tooltipTitle={"Settings"}
            tooltipOpen={true}
            tooltipPlacement={tooltipPlacement}
            color={"primary"}
          />]
      }}
      />
      <SettingsDialog
        fullWidth maxWidth={"xs"} open={open} onClose={toggleOpen}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            dispatch(setStreamConfig({...settingsState}));
            toggleOpen()
          },
        }}>
        <DialogTitle>
          Stream Settings
        </DialogTitle>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
          <ListSubheader color={"primary"}>Video settings</ListSubheader>
          <ListItemButton dense onClick={openMenu("resolution")}>
            <ListItemIcon>
              <ImageAspectRatioTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Resolution"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {settingsState.resolution?.width} x {settingsState.resolution?.height}</Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton dense onClick={openMenu("framerate")}>
            <ListItemIcon>
              <SpeedTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Framerate"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {settingsState.framerate} fps</Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton onClick={() => setMenuOpen("bitrate")} dense>
            <ListItemIcon>
              <NetworkCheck/>
            </ListItemIcon>
            <ListItemText primary={"Bitrate"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {settingsState.bitrate / (1024 * 1024)} mbps</Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
          <Collapse in={menuOpen === "bitrate"}>
            <ListItem dense>
              <ListItemText inset>
                <Slider onChange={(e, v) => setSettingState({...settingsState, bitrate: v * 1024 * 1024})}
                        valueLabelDisplay="auto" min={0.5} step={0.5}
                        max={25} value={settingsState.bitrate / (1024 * 1024)}/>
              </ListItemText>
            </ListItem>
          </Collapse>
          <ListItem dense>
            <ListItemIcon>
              <ImageAspectRatioTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Change Resolution"}/>
            <Checkbox size={"small"} onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                      checked={settingsState.change_resolution}/>
          </ListItem>
          <ListSubheader color={"primary"}>Miscellaneous</ListSubheader>
          <ListItemButton dense onClick={openMenu("idr_interval")}>
            <ListItemIcon>
              <RefreshTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"IDR Interval"}>
            </ListItemText>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {settingsState.idr_interval || 0} frames</Typography>

            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItem dense>
            <ListItemIcon>
              <CodeTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Source options"}/>
            <TextField size={"small"} defaultValue={settingsState?.extra_opt?.source}
                       placeholder={"extra options for video source"}
                       name={"source"}
                       onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"source": event.target.value}))}/>
          </ListItem>
          <ListItem dense>
            <ListItemIcon>
              <CodeTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Filter options"}/>
            <TextField size={"small"} defaultValue={settingsState?.extra_opt?.filter}
                       placeholder={"extra options for color filter"}
                       name={"Filter"}
                       onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"filter": event.target.value}))}/>
          </ListItem>
          <ListItem dense>
            <ListItemIcon>
              <CodeTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Processor options"}/>
            <TextField size={"small"} defaultValue={settingsState?.extra_opt?.processor}
                       placeholder={"extra options for video encoder"}
                       name={"Processor"}
                       onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"processor": event.target.value}))}/>
          </ListItem>
        </List>
        <Menu open={menuOpen === "resolution"} anchorEl={anchorEl} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} onClose={closeMenu}>
          <MenuItem onClick={() => setValue("resolution", {width: 1280, height: 720})} value="1280x720">
            1280 x 720
          </MenuItem>
          <MenuItem onClick={() => setValue("resolution", {width: 1920, height: 1080})} value="1920x1080">
            1920 x 1080
          </MenuItem>
          <MenuItem onClick={() => setValue("resolution", {width: 2560, height: 1440})} value="2560x1440">
            2560 x 1440
          </MenuItem>
          <MenuItem onClick={() => setValue("resolution", {width: 3840, height: 2160})} value="3840x2160">
            3840 x 2160
          </MenuItem>
          <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
        </Menu>
        <Menu open={menuOpen === "framerate"} anchorEl={anchorEl} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} onClose={closeMenu}>
          <MenuItem onClick={() => setValue("framerate", 30)}>
            30 fps
          </MenuItem>
          <MenuItem onClick={() => setValue("framerate", 60)}>
            60 fps
          </MenuItem>
          <MenuItem onClick={() => setValue("framerate", 120)}>
            120 fps
          </MenuItem>

        </Menu>
        <Menu open={menuOpen === "idr_interval"} anchorEl={anchorEl} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} onClose={closeMenu}>
          <MenuItem onClick={() => setValue("idr_interval", 0)}>
            0 frames
          </MenuItem>
          <MenuItem onClick={() => setValue("idr_interval", 500)}>
            500 frames
          </MenuItem>
          <MenuItem onClick={() => setValue("idr_interval", 1000)}>
            1000 frames
          </MenuItem>

        </Menu>
        <DialogActions>
          <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>cancel</Button>
          <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
        </DialogActions>
      </SettingsDialog>
      <Dialog open={menuOpen === "resolution_custom"}
              onClose={closeMenu}
              PaperProps={{
                component: 'form',
                onSubmit: (e) => {
                  e.preventDefault();
                  let formdata = new FormData(e.currentTarget);
                  let dataJson = Object.fromEntries(formdata.entries());
                  setValue("resolution", {width: Number(dataJson.width), height: Number(dataJson.height)})
                }
              }}
      >
        <DialogTitle>Custom Resolution</DialogTitle>
        <DialogContent>
          <TextField autoFocus sx={{m: 1}} defaultValue={settingsState?.resolution?.width} label={"Width"}
                     name={"width"}/>
          <TextField sx={{m: 1}} label={"Height"} name={"height"} defaultValue={settingsState?.resolution?.height}/>
        </DialogContent>
        <DialogActions>
          <Button type={"submit"}>Save</Button>
        </DialogActions>
      </Dialog>
  </>
}


export default Overlay;