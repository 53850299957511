import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  styled,
  Typography
} from "@mui/material";
import {AccountCircleTwoTone, Cancel, MailTwoTone, Save, SecurityTwoTone, SendOutlined} from "@mui/icons-material";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import forgot_password from "../../api/forgot_password";
import {setAlert} from "../../redux/AlertSlice";

const ProfileDialog = styled(Dialog)((theme) => ({
  '& .MuiDialog-paper': {}
}))

const StyledContent = styled(DialogContent)(theme => ({
  '&.MuiDialogContent-root': {
    padding: 0,
  }
}))

const Profile = ({open, onClose}) => {
  const user = useSelector(state => state.auth.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const closeMenu = () => {
    setMenuOpen(false)
  };
  const openMenu = () => {
    setMenuOpen(true)
  };
  return <>
    <ProfileDialog
      fullWidth maxWidth={"xs"} open={open} onClose={onClose}
      PaperProps={{
        component: 'form', onSubmit: (event) => {
          event.preventDefault();
          onClose();
        },
      }}
    >
      <DialogTitle>
        Profile
      </DialogTitle>
      <StyledContent scroll={"paper"}>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
          <ListSubheader color={"primary"}>Account</ListSubheader>
          <ListItem dense>
            <ListItemIcon>
              <AccountCircleTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"First name"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {user?.first_name}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem dense>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText primary={"Last name"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {user?.last_name}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem dense>
            <ListItemIcon>
              <MailTwoTone/>
            </ListItemIcon>
            <ListItemText primary={"Email"}/>
            <ListItemSecondaryAction>
              <Typography variant={"body2"}
                          color={"textSecondary"}> {user?.email}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListSubheader color={"primary"}>Security</ListSubheader>
          <Button sx={{mx: 2}} onClick={openMenu} color={"secondary"} startIcon={<SecurityTwoTone/>}>Change
            Password</Button>
        </List>
      </StyledContent>
      <DialogActions>
        <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>Close</Button>
        <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
      </DialogActions>
    </ProfileDialog>
    <Dialog open={menuOpen}
            onClose={closeMenu}
            PaperProps={{
              component: 'form', onSubmit: (e) => {
                e.preventDefault();
                setIsLoading(true);
                forgot_password(user?.email).then(r => {
                  if (r.success) {
                    dispatch(setAlert({msg: "Please check your email for reset link", severity: "success"}))
                    closeMenu();
                  } else {
                    dispatch(setAlert({msg: "Something went wrong", severity: "error"}))
                    console.error(r)
                  }
                }).catch(e => {
                  console.error(e)
                  dispatch(setAlert({msg: "Something went wrong", severity: "error"}))
                }).finally(() => {
                  setIsLoading(false)
                })
              }
            }}
    >
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        <Typography variant={"body1"}>A password reset email will be sent to you. Continue? </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeMenu} color={"error"}>Close</Button>
        <Box sx={{position: 'relative'}}>
          <Button
            disabled={isLoading}
            color={"primary"}
            type={'submit'} endIcon={<SendOutlined/>} variant={"contained"}>
            Reset Link
          </Button>
          {isLoading && (<CircularProgress size={24} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}/>)}
        </Box>
      </DialogActions>
    </Dialog>
  </>
}
export default Profile;