import {
  Box,
  Button,
  Card,
  CardActions, CircularProgress,
  Container,
  Divider,
  InputAdornment, Link,
  styled,
  TextField,
  Typography, useTheme
} from "@mui/material";
import {
  AccountCircleOutlined,
  KeyOutlined, SendOutlined,
} from "@mui/icons-material";

import AppLogo from '../../images/icon.svg'
import {clearState, loginThunk} from "../../redux/LoginSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams, Link as Rlink} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {checkAuth} from "../../redux/AuthSlice";

const StyledContainer = styled(Container)(
  ({theme}) => ({
    alignSelf: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`
  })
)

const FormCard = (props) => {
  return <Card {...props} component={'form'}/>
}
const Login = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login.loadingState === 'loading' || state.auth.loadingState === 'loading')
  const errors_raw = useSelector((state) => state.login.errors)
  const errors = useMemo(() => {
    let errMap = {}
    for (const k in errors_raw) {
      errMap[k] = errors_raw[k].join(". ")
    }
    return errMap

  }, [errors_raw]);

  const loginStatus = useSelector(state => state.login.loadingState)
  const authenticated = useSelector(state => state.auth.authenticated)

  const [searchParams] = useSearchParams();
  const nextUrl = useMemo(() => {
    return searchParams.get("next") || "/";
  }, [searchParams]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState())
  }, []);

  useEffect(() => {
    if (loginStatus === "success" && !authenticated) {
      dispatch(checkAuth())
    }
  }, [loginStatus]);

  useEffect(() => {
    if (authenticated) {
      navigate(nextUrl)
    }
  }, [nextUrl, authenticated]);
  const attemptLogin = (e) => {
    const formData = new FormData(e.target);
    let payload = Object.fromEntries(formData.entries())
    dispatch(loginThunk(payload));
    e.preventDefault();
  }
  return (<>
    <StyledContainer maxWidth={"xs"}
                     component={FormCard}
                     elevation={10}
                     variant={'elevation'} type={'glass'}
                     onSubmit={attemptLogin}
    >

      <img style={{height: theme.spacing(4), width: 'auto'}} src={AppLogo} alt={"logo"}/>
      <Box sx={{my: 2}}>
        <Typography sx={{my: 2}} variant={"h4"} color={'primary'}>Sign In</Typography>
        <Typography variant={"h6"} color={'textSecondary'}>
          to continue to <Typography variant={"h6"} component={'span'}
                                     color={'textPrimary'}>
          Gamearena
        </Typography>
        </Typography>
      </Box>

      <TextField
        disabled={isLoading}
        autoFocus
        sx={{my: 2}}
        name={'email'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlined color={'primary'}/>
            </InputAdornment>
          )
        }}
        label={"Email"}
        type={"email"}
        error={errors?.email}
        helperText={errors?.email}

      />
      <TextField
        disabled={isLoading}
        sx={{my: 2}}
        name={'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <KeyOutlined color={'primary'}/>
            </InputAdornment>
          )
        }}
        error={errors?.password}
        helperText={errors?.password}
        autoComplete={'off'}
        label={"Password"} type={"password"}/>
      <Link component={Rlink} to='/forgot-password' textAlign={'right'}>Forgot Password</Link>

      <Typography
        sx={{mt: 2}}
        variant={'body1'} color={'textSecondary'}>Don't have an account? <Link component={Rlink}
                                                                               to={"/signup?next=" + nextUrl}>Signup</Link></Typography>
      <Divider sx={{mt: 2}}/>
      <CardActions sx={{my: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Box sx={{position: 'relative'}}>
          <Button
            disabled={isLoading}
            type={'submit'} endIcon={<SendOutlined/>} variant={"outlined"}>
            Login
          </Button>
          {isLoading && (<CircularProgress size={24} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}/>)}
        </Box>
      </CardActions>
    </StyledContainer>
  </>)
}

export default Login