import {useEffect} from "react";

const WakeLock = () => {
  useEffect(() => {
    let lock;
    let closed = false;
    if (document.hidden) {
      document.addEventListener("visibilitychange", e => {
        if (!document.hidden) {
          navigator.wakeLock?.request("screen").then(l => {
            if (closed) {
              console.log("released before locking");
              l.release()
              return;
            }
            console.log("wake lock");
            lock = l;
          }).catch(e => {
            console.log("failed to lock the sentinal")
          })
        }
      }, {once: true})
    } else {
      navigator.wakeLock?.request("screen").then(l => {
        console.log("wake lock");
        if (closed) {
          console.log("released before locking");
          l.release()
          return;
        }
        lock = l;
      }).catch(e => {
        console.log("failed to lock the sentinal")
      })
    }

    return () => {
      closed = true;
      if (lock) {
        console.log("Lock released")
        lock.release();
      }
    }
  }, []);
  return <></>
}

export default WakeLock