import VideoProcessor from "./VideoProcessor";

const ConnectReq = "connect_request"
const ConnectRes = "connect_response"

const STATUSES = {
  uninitialized: "not initialized",
  requested: "Requesting connection",
  accepted: "host accepted request",
  rejected: "host rejected request",
  streamInit: "requested stream initialization",
  offerReceived: "received offer",
  answerSent: "answer sent",
  offerSent: "offer sent",
  answerReceived: "answer received",
  connected: "connected"
}

const MsgTyp = {
  InitStream: "init_stream",
  RequestOffer: "request_offer",

  HostOffer: "host_offer",
  HostAnswer: "host_answer",
  HostCandidate: "host_candidate",

  ClientAnswer: 'client_answer',
  ClientOffer: 'client_offer',
  ClientReOffer: "client_reoffer",
  ClientCandidate: "client_candidate",

  ErrorMsg: "error"

}


class WebrtcClient {
  hostID;
  wsClient;
  peerConn;
  #target;
  timer;
  #status = STATUSES.uninitialized;

  onStatusChanged;
  onInputReady;

  videoChannel;
  inputChannel;
  controlChannel;
  audioChannel;

  videoProcessor;

  candidates = [];
  ontrack;

  constructor(hostID, wsClient, statusChangeHandler, onInputReady, config) {
    this.onStatusChanged = statusChangeHandler
    this.onInputReady = onInputReady
    this.#target = {type: "host", id: hostID}
    this.hostID = hostID;
    this.wsClient = wsClient
    wsClient.setMsgHandler(hostID, this._msgHandler.bind(this))
    this.send(ConnectReq, {version: "0.1"})
    this.status = STATUSES.requested
    this.timer = setTimeout(() => {
      this.destroy()
    }, 10000)

    this.videoProcessor = new VideoProcessor();
    this.videoProcessor.onBadFrame = () => {
      this.videoChannel.send("pli")
    }
    this.config = config;
  }

  get status() {
    return this.#status
  }

  set status(status) {
    this.#status = status;
    this.onStatusChanged && this.onStatusChanged(status)
  }

  set onVideoFrameReceived(handler) {
    this.videoProcessor.onVideoFrame = handler
  }

  send(msgType, payload) {
    this.wsClient.send({type: msgType, target: this.#target, payload})
  }

  #ontrack(e) {
    this.audioChannel = e.streams[0];
    this.ontrack && this.ontrack(e);
  };

  addIceCandidate(payload) {
    if (payload) {
      try {
        const candidate = new RTCIceCandidate(payload);
        this.candidates.push(candidate)
      } catch (e) {
        console.log(e)
      }
    }

    if (this.candidates.length > 0 && this.peerConn && this.peerConn.remoteDescription) {
      this.candidates.forEach(c => {
        this.peerConn.addIceCandidate(c)
      })
      this.candidates = []
    }
  }

  #videoDataReceived(packet) {
    this.videoProcessor.push(packet);
  }

  startPC(offer) {
    this.status = STATUSES.offerReceived
    let peerconn = new RTCPeerConnection({
      iceServers: [{urls: 'stun:gamearena.tech:19302'}, {
        urls: ["turn:gamearena.tech:19302"],
        username: this.wsClient.turnCred.turn_user,
        credential: this.wsClient.turnCred.turn_pass,
        credentialType: "password",
      },]
    });
    this.peerConn = peerconn
    peerconn.onicecandidate = ({candidate}) => {
      this.send(MsgTyp.ClientCandidate, candidate)
    }
    peerconn.onnegotiationneeded = async () => {
      try {
        if (peerconn.signalingState !== "stable" || !peerconn.localDescription) return;
        let offer = await peerconn.createOffer();
        console.log("negotiation needed!!!!")
        offer = new RTCSessionDescription(offer);
        await peerconn.setLocalDescription(offer);
        this.send(MsgTyp.ClientReOffer, peerconn.localDescription);
      } catch (err) {
        console.log(err);
      }
    }

    peerconn.ontrack = this.#ontrack.bind(this);
    peerconn.ondatachannel = ev => {
      switch (ev.channel.label) {
        case "video":
          ev.channel.onopen = () => {
            this.videoChannel = ev.channel
          }
          ev.channel.onmessage = (ev) => {
            this.#videoDataReceived(ev.data)
          }
          break;
        case "control":
          ev.channel.onopen = () => {
            this.controlChannel = ev.channel
          }
          break;
        case "input":
          ev.channel.onopen = () => {
            this.inputChannel = ev.channel
            this.onInputReady && this.onInputReady();
          }
          break;
        default:
          console.error("unexpected channel received.", ev.channel.label)
      }
    }
    peerconn.oniceconnectionstatechange = (e) => {
      this.status = e.target.iceConnectionState;
    }
    let f;
    if (offer) {

      f = async () => {
        try {
          await peerconn.setRemoteDescription(offer)
          let ans = await peerconn.createAnswer()
          await peerconn.setLocalDescription(ans)
          this.send(MsgTyp.ClientAnswer, peerconn.localDescription)
          this.addIceCandidate(peerconn)
          this.status = STATUSES.answerSent
        } catch (e) {
          console.error(e)
          this.destroy()
        }
      }
    } else {
      f = async () => {
        try {
          peerconn.createDataChannel("temp", {priority: "high"})
          let of = await peerconn.createOffer({iceRestart: true})
          await peerconn.setLocalDescription(of)
          this.send(MsgTyp.ClientOffer, peerconn.localDescription)
          this.status = STATUSES.offerSent
        } catch (e) {
          console.error(e)
          this.destroy()
        }
      }
    }
    f()
  }

  setConfig(config) {
    this.config = config;
    if (this.controlChannel) {
      this.controlChannel.send(JSON.stringify(config));
    }
  }

  startConnection() {
    let config = {}
    Object.assign(config, this.config)
    this.send(MsgTyp.InitStream, config);
    this.send(MsgTyp.RequestOffer, config);
    this.status = STATUSES.streamInit;
  }

  _msgHandler(data) {
    switch (data.type) {
      case ConnectRes:
        clearTimeout(this.timer)
        this.timer = null;
        if (data.payload.accepted) {
          this.status = STATUSES.accepted
          this.startConnection()
        } else {
          this.status = STATUSES.rejected
        }
        break;
      case MsgTyp.HostOffer:
        this.startPC(data.payload)
        break;
      case MsgTyp.HostCandidate:
        this.addIceCandidate(data.payload)
        break;
      case MsgTyp.HostAnswer:
        this.status = STATUSES.answerReceived
        if (this.peerConn) {
          this.peerConn.setRemoteDescription(data.payload).then(() => this.addIceCandidate())
        }
        break
      case MsgTyp.ErrorMsg:
        console.error(data.payload)
        break;
      default:
        console.error("Unexpected message", data)
    }
  }

  destroy() {
    if (this.peerConn) {
      this.peerConn.close();
    }
  }
}

export default WebrtcClient