import React, {useEffect, useRef, useState} from "react";
import KbdMouse from "./kbd_mouse";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import touchGestures from "./touchGestures";

const downKeys = {};
const KeyboardInput = ({cli, inControl, onControlChange, move_multiplier, scroll_multiplier}) => {

  const element = useRef();
  const [kbdInp, setKbdInp] = useState(null);
  const releaseInput = () => {
    onControlChange(false);
  }
  const storedClientSettings = useSelector(state => state.client_settings.client_Keyboard_type);

  useEffect(() => {
    if (element.current && inControl) {
      element.current.focus();
      document.addEventListener("pointerlockchange", lockChangeHandler)
      let promise = element.current.requestPointerLock({
        unadjustedMovement: true,
      });

      if (!promise) {
        promise = element.current.requestPointerLock();
      } else {
        promise.catch((error) => {
          if (error.name === "NotSupportedError") {
            // Some platforms may not support unadjusted movement.
            // You can request again a regular pointer lock.
            console.log("re-locking pointer ")
            return element.current.requestPointerLock();
          }
        }).then(() => {
          lockChangeHandler()
        })
      }
      navigator.keyboard.lock()
      let keyboardInput = new KbdMouse(cli)
      setKbdInp(keyboardInput);
      let touch = new touchGestures(element.current, keyboardInput, scroll_multiplier, move_multiplier );
      return () => {
        touch.destroy();
        navigator.keyboard.unlock();
        document.exitPointerLock();
        document.removeEventListener("pointerlockchange", lockChangeHandler)
      }
    }
  }, [inControl, element]);

  const lockChangeHandler = (event) => {
    if (document.pointerLockElement) {
      console.log("collecting input")
    } else {
      releaseInput()
    }
  }
  useEffect(() => {
    if (element.current) {
      const ele = element.current;
      ele.addEventListener("wheel", mouseScroll)
      return () => ele.removeEventListener("wheel", mouseScroll)
    }
  }, [element, kbdInp]);

  const mouseMove = (e) => {
    kbdInp?.sendMouseMove({x: e.movementX * move_multiplier, y: e.movementY * move_multiplier})
  }
  const mouseDown = (e) => {
    kbdInp?.sendMouseDown(e.button)
  }
  const mouseUp = (e) => {
    kbdInp?.sendMouseUp(e.button)
  }

  const keyDown = (e) => {
    if ((e.key === "m" || e.key === "M") && e.ctrlKey) {
      releaseInput()
      kbdInp?.sendKeyUp(17)
      kbdInp?.sendKeyUp(e.keyCode)
      return
    }

    if (process.env.NODE_ENV === "development") {
      if ((e.key === "y" || e.key === "Y") && e.ctrlKey) {
        releaseInput()
        kbdInp?.sendKeyUp(17)
        kbdInp?.sendKeyUp(e.keyCode)
        return
      }
    }
    e.preventDefault()
    const keyCode = storedClientSettings !== "Windows" ? macToWindowsKeysMap(e.keyCode) : e.keyCode
    kbdInp?.sendKeyDown(keyCode)
  }

  const macToWindowsKeysMap = (keyInCode) => {
    const keyMap = new Map([
      [91, 17], // command pointing to windows in mac change to ctrl
      [17, 91]]); // ctrl to windows
    return keyMap.get(keyInCode) || keyInCode;
  }


  const keyUp = (e) => {
    kbdInp?.sendKeyUp(e.keyCode)
  }


  const mouseScroll = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const dx = e.deltaX * (e.deltaMode === WheelEvent.DOM_DELTA_LINE ? 120 : 1);
    const dy = e.deltaY * (e.deltaMode === WheelEvent.DOM_DELTA_LINE ? 120 : 1);
    console.log(dx, e.deltaX, dy, e.deltaY)
    kbdInp?.sendMouseScroll({x: dx * scroll_multiplier, y: -1 * dy * scroll_multiplier})
  }
  return <Box sx={{
    height: "100vh", width: "100vw", position: "absolute", '&:focus': {
      outline: "none",
    }
  }} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp}
              onKeyDown={keyDown} onKeyUp={keyUp} ref={element} tabIndex={0}/>
}
export default KeyboardInput;