import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {checkAuth, logoutThunk} from "../../redux/AuthSlice";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import logo from "../../images/icon.svg"
import Devices from "../Devices/Devices";
import OverlaySetting from "../../components/StreamClient/OverlaySetting";
import Profile from "../../components/Profile/Profile";
import {LogoutTwoTone, PersonTwoTone} from "@mui/icons-material";

const WelcomeNode = ({user, ...props}) => {

  return (<Typography color={"textPrimary"} alignSelf={'center'} textAlign={'center'} variant={"h2"}>
    Welcome, <Typography component={'span'} variant={"h2"} color={"primary"}>{user?.last_name}</Typography>
  </Typography>)

}

const UserBadge = ({user, logout}) => {
  const theme = useTheme();
  const [menuBtn, setMenuBtn] = useState(null);
  const open = Boolean(menuBtn)
  const [profileOpen, setProfileOpen] = useState(false);
  const handleClick = (e) => {
    setMenuBtn(e.currentTarget)
  }
  const handleClose = () => {
    setMenuBtn(null)
  }
  return (<>
    <IconButton ref={menuBtn} onClick={handleClick}><Avatar
      sx={{background: theme.palette.secondary.main}}>{user.first_name[0]}</Avatar></IconButton>
    <Menu onClose={handleClose} anchorEl={menuBtn} open={open}>
      <MenuItem dense onClick={() => setProfileOpen(true)} sx={{minWidth:'150px'}} >
        <ListItemIcon>
          <PersonTwoTone fontSize={"small"}/>
        </ListItemIcon>
        <ListItemText primary={"Profile"}/>
      </MenuItem>
      <MenuItem dense onClick={logout}>
        <ListItemIcon>
          <LogoutTwoTone fontSize={"small"}/>
        </ListItemIcon>
        <ListItemText primary={"logout"}/>
      </MenuItem>
    </Menu>
    <Profile open={profileOpen} onClose={() => setProfileOpen(false)}/>
  </>)
}
const Home = () => {
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutThunk())
  }
  useEffect(() => {
    dispatch(checkAuth())
  }, []);

  return (<>
    <AppBar variant={"elevation"} elevation={0} color={"transparent"}>
      <Toolbar variant={"regular"}>
        <img style={{height: "auto", width: 32}} src={logo} alt={"logo"}/>
        <Box flex={1}/>

        <OverlaySetting/>
        {user ?
          <UserBadge user={user} logout={logout}/>
          : <Button component={Link} to={"/login"}>Login</Button>}
      </Toolbar>
    </AppBar>
    <Routes>
      <Route path={"/"} element={<Devices/>}></Route>
    </Routes>
  </>)
}
export default Home